exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-case-studies-details-index-tsx": () => import("./../../../src/pages/case-studies/details/index.tsx" /* webpackChunkName: "component---src-pages-case-studies-details-index-tsx" */),
  "component---src-pages-case-studies-index-tsx": () => import("./../../../src/pages/case-studies/index.tsx" /* webpackChunkName: "component---src-pages-case-studies-index-tsx" */),
  "component---src-pages-company-about-index-tsx": () => import("./../../../src/pages/company/about/index.tsx" /* webpackChunkName: "component---src-pages-company-about-index-tsx" */),
  "component---src-pages-company-blog-index-tsx": () => import("./../../../src/pages/company/blog/index.tsx" /* webpackChunkName: "component---src-pages-company-blog-index-tsx" */),
  "component---src-pages-company-process-index-tsx": () => import("./../../../src/pages/company/process/index.tsx" /* webpackChunkName: "component---src-pages-company-process-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-index-tsx": () => import("./../../../src/pages/privacy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-index-tsx" */),
  "component---src-pages-products-index-tsx": () => import("./../../../src/pages/products/index.tsx" /* webpackChunkName: "component---src-pages-products-index-tsx" */),
  "component---src-pages-security-overview-index-tsx": () => import("./../../../src/pages/security/overview/index.tsx" /* webpackChunkName: "component---src-pages-security-overview-index-tsx" */),
  "component---src-pages-security-soc-2-index-tsx": () => import("./../../../src/pages/security/soc2/index.tsx" /* webpackChunkName: "component---src-pages-security-soc-2-index-tsx" */),
  "component---src-pages-services-index-tsx": () => import("./../../../src/pages/services/index.tsx" /* webpackChunkName: "component---src-pages-services-index-tsx" */),
  "component---src-pages-terms-index-tsx": () => import("./../../../src/pages/terms/index.tsx" /* webpackChunkName: "component---src-pages-terms-index-tsx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-2024-small-business-grants-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/2024-small-business-grants/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-2024-small-business-grants-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-4-benefits-of-click-and-collect-for-small-businesses-and-startups-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/4-benefits-of-click-and-collect-for-small-businesses-and-startups/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-4-benefits-of-click-and-collect-for-small-businesses-and-startups-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-4-day-workweek-pros-and-cons-for-small-businesses-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/4-day-workweek-pros-and-cons-for-small-businesses/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-4-day-workweek-pros-and-cons-for-small-businesses-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-4-reasons-why-backlinks-are-important-for-your-small-business-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/4-reasons-why-backlinks-are-important-for-your-small-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-4-reasons-why-backlinks-are-important-for-your-small-business-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-4-takeaways-from-the-verizon-outage-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/4-takeaways-from-the-verizon-outage/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-4-takeaways-from-the-verizon-outage-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-4-tips-for-creating-a-beautifully-designed-api-with-great-ux-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/4-tips-for-creating-a-beautifully-designed-api-with-great-ux/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-4-tips-for-creating-a-beautifully-designed-api-with-great-ux-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-4-ways-to-use-embedded-finance-in-small-business-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/4-ways-to-use-embedded-finance-in-small-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-4-ways-to-use-embedded-finance-in-small-business-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-5-reasons-small-businesses-should-create-custom-404-pages-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/5-reasons-small-businesses-should-create-custom-404-pages/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-5-reasons-small-businesses-should-create-custom-404-pages-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-5-reasons-small-businesses-should-localize-their-websites-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/5-reasons-small-businesses-should-localize-their-websites/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-5-reasons-small-businesses-should-localize-their-websites-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-5-reasons-why-more-small-businesses-are-outsourcing-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/5-reasons-why-more-small-businesses-are-outsourcing/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-5-reasons-why-more-small-businesses-are-outsourcing-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-5-things-to-include-in-a-consulting-report-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/5-things-to-include-in-a-consulting-report/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-5-things-to-include-in-a-consulting-report-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-5-tips-for-building-your-company-culture-in-a-remote-world-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/5-tips-for-building-your-company-culture-in-a-remote-world/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-5-tips-for-building-your-company-culture-in-a-remote-world-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-6-hidden-costs-of-custom-software-development-how-to-avoid-budget-creep-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/6-hidden-costs-of-custom-software-development-how-to-avoid-budget-creep/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-6-hidden-costs-of-custom-software-development-how-to-avoid-budget-creep-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-6-reasons-to-improve-website-accessibility-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/6-reasons-to-improve-website-accessibility/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-6-reasons-to-improve-website-accessibility-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-6-things-small-business-owners-should-know-about-a-credit-crunch-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/6-things-small-business-owners-should-know-about-a-credit-crunch/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-6-things-small-business-owners-should-know-about-a-credit-crunch-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-7-reaasons-why-your-small-business-needs-a-tech-partner-with-devops-expertise-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/7-reaasons-why-your-small-business-needs-a-tech-partner-with-devops-expertise/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-7-reaasons-why-your-small-business-needs-a-tech-partner-with-devops-expertise-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-7-reasons-why-a-podcast-can-benefit-your-small-business-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/7-reasons-why-a-podcast-can-benefit-your-small-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-7-reasons-why-a-podcast-can-benefit-your-small-business-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-8-reasons-for-user-generated-content-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/8-reasons-for-user-generated-content/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-8-reasons-for-user-generated-content-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-8-software-development-trends-for-2021-and-how-to-be-sure-your-software-keeps-up-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/8-software-development-trends-for-2021-and-how-to-be-sure-your-software-keeps-up/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-8-software-development-trends-for-2021-and-how-to-be-sure-your-software-keeps-up-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-8-tips-for-tax-write-offs-in-small-business-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/8-tips-for-tax-write-offs-in-small-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-8-tips-for-tax-write-offs-in-small-business-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-a-guide-to-qa-testing-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/a-guide-to-qa-testing/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-a-guide-to-qa-testing-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-ach-vs-wire-transfer-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/ach-vs-wire-transfer/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-ach-vs-wire-transfer-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-agile-everything-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/agile-everything/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-agile-everything-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-agile-recruitment-and-how-it-can-help-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/agile-recruitment-and-how-it-can-help/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-agile-recruitment-and-how-it-can-help-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-api-economy-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/API-economy/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-api-economy-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-apple-mail-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/apple-mail/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-apple-mail-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-async-video-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/async-video/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-async-video-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-attention-marketing-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/attention-marketing/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-attention-marketing-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-backend-hiring-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/backend-hiring/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-backend-hiring-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-benefits-of-cloud-computing-for-small-business-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/benefits-of-cloud-computing-for-small-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-benefits-of-cloud-computing-for-small-business-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-benefits-of-ewa-in-small-business-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/benefits-of-EWA-in-small-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-benefits-of-ewa-in-small-business-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-blockchain-basics-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/blockchain-basics/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-blockchain-basics-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-bnpl-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/BNPL/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-bnpl-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-boost-your-small-business-online-visibility-with-google-eat-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/boost-your-small-business-online-visibility-with-google-eat/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-boost-your-small-business-online-visibility-with-google-eat-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-business-loans-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/business-loans/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-business-loans-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-business-loc-1-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/business-LOC-1/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-business-loc-1-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-business-setup-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/business-setup/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-business-setup-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-can-generative-ai-help-your-small-business-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/can-generative-AI-help-your-small-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-can-generative-ai-help-your-small-business-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-can-the-new-working-capital-pilot-program-help-your-small-business-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/can-the-new-Working-Capital-Pilot-Program-help-your-small-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-can-the-new-working-capital-pilot-program-help-your-small-business-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-can-we-get-real-here-how-to-avoid-fake-agile-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/can-we-get-real-here-how-to-avoid-fake-agile/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-can-we-get-real-here-how-to-avoid-fake-agile-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-cash-flow-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/cash-flow/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-cash-flow-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-celebrating-dei-in-entrepreneurship-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/celebrating-dei-in-entrepreneurship/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-celebrating-dei-in-entrepreneurship-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-cfo-skills-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/cfo-skills/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-cfo-skills-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-could-digital-tips-cause-small-businesses-more-harm-than-good-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/could-digital-tips-cause-small-businesses-more-harm-than-good/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-could-digital-tips-cause-small-businesses-more-harm-than-good-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-creating-a-pitch-deck-for-your-new-tech-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/creating-a-pitch-deck-for-your-new-tech/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-creating-a-pitch-deck-for-your-new-tech-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-customer-service-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/customer-service/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-customer-service-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-cybersecurity-tips-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/cybersecurity-tips/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-cybersecurity-tips-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-data-driven-decision-making-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/data-driven-decision-making/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-data-driven-decision-making-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-design-thinking-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/design-thinking/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-design-thinking-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-digital-business-banking-the-modern-way-to-manage-your-small-businesss-finances-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/digital-business-banking-the-modern-way-to-manage-your-small-businesss-finances/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-digital-business-banking-the-modern-way-to-manage-your-small-businesss-finances-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-digital-nomad-lifestyle-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/digital-nomad-lifestyle/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-digital-nomad-lifestyle-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-disruptive-technology-as-an-entrepreneur-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/disruptive-technology-as-an-entrepreneur/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-disruptive-technology-as-an-entrepreneur-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-diy-bookkeeping-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/DIY-bookkeeping/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-diy-bookkeeping-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-does-your-small-business-need-a-v-ciso-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/does-your-small-business-need-a-vCISO/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-does-your-small-business-need-a-v-ciso-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-email-marketing-101-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/email-marketing-101/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-email-marketing-101-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-employer-brand-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/employer-brand/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-employer-brand-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-expense-tracking-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/expense-tracking/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-expense-tracking-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-financial-statements-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/financial-statements/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-financial-statements-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-fixed-vs-time-and-material-pricing-models-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/fixed-vs-time-and-material-pricing-models/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-fixed-vs-time-and-material-pricing-models-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-flexible-budget-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/flexible-budget/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-flexible-budget-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-funded-now-what-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/funded-now-what/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-funded-now-what-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-funding-challenges-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/funding-challenges/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-funding-challenges-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-future-tech-what-it-really-means-for-software-to-be-relevant-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/future-tech-what-it-really-means-for-software-to-be-relevant/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-future-tech-what-it-really-means-for-software-to-be-relevant-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-great-resignation-hiring-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/great-resignation-hiring/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-great-resignation-hiring-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-happy-culture-1-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/happy-culture-1/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-happy-culture-1-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-health-insurance-8-steps-and-benefits-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/health-insurance-8-steps-and-benefits/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-health-insurance-8-steps-and-benefits-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-how-can-integrated-payments-help-small-businesses-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/how-can-integrated-payments-help-small-businesses/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-how-can-integrated-payments-help-small-businesses-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-how-fast-is-your-small-business-website-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/how-fast-is-your-small-business-website/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-how-fast-is-your-small-business-website-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-how-much-will-it-really-cost-to-build-my-app-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/how-much-will-it-really-cost-to-build-my-app/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-how-much-will-it-really-cost-to-build-my-app-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-how-remote-work-will-change-2024-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/how-remote-work-will-change-2024/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-how-remote-work-will-change-2024-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-how-small-businesses-can-repay-pandemic-loans-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/how-small-businesses-can-repay-pandemic-loans/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-how-small-businesses-can-repay-pandemic-loans-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-how-to-budget-for-your-small-business-tips-tricks-and-more-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/how-to-budget-for-your-small-business-tips-tricks-and-more/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-how-to-budget-for-your-small-business-tips-tricks-and-more-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-how-to-calculate-total-addressable-market-for-your-small-business-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/how-to-calculate-total-addressable-market-for-your-small-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-how-to-calculate-total-addressable-market-for-your-small-business-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-how-to-choose-a-lender-for-your-small-business-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/how-to-choose-a-lender-for-your-small-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-how-to-choose-a-lender-for-your-small-business-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-how-to-create-a-customer-profile-in-4-steps-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/how-to-create-a-customer-profile-in-4-steps/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-how-to-create-a-customer-profile-in-4-steps-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-how-to-create-an-omnichannel-customer-experience-for-your-small-business-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/how-to-create-an-omnichannel-customer-experience-for-your-small-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-how-to-create-an-omnichannel-customer-experience-for-your-small-business-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-how-to-democratize-knowledge-in-your-small-business-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/how-to-democratize-knowledge-in-your-small-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-how-to-democratize-knowledge-in-your-small-business-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-how-to-manage-a-cash-surplus-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/how-to-manage-a-cash-surplus/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-how-to-manage-a-cash-surplus-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-how-to-manage-late-payments-and-cash-flow-in-your-small-business-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/how-to-manage-late-payments-and-cash-flow-in-your-small-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-how-to-manage-late-payments-and-cash-flow-in-your-small-business-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-how-to-manage-stagflation-as-a-small-business-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/how-to-manage-stagflation-as-a-small-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-how-to-manage-stagflation-as-a-small-business-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-how-to-manage-swipe-fees-in-small-business-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/how-to-manage-swipe-fees-in-small-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-how-to-manage-swipe-fees-in-small-business-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-how-to-prepare-your-small-business-for-a-banking-crisis-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/how-to-prepare-your-small-business-for-a-banking-crisis/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-how-to-prepare-your-small-business-for-a-banking-crisis-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-how-to-repay-small-business-debt-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/how-to-repay-small-business-debt/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-how-to-repay-small-business-debt-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-how-to-scale-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/how-to-scale/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-how-to-scale-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-how-to-use-a-value-proposition-canvas-in-small-business-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/how-to-use-a-value-proposition-canvas-in-small-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-how-to-use-a-value-proposition-canvas-in-small-business-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-how-to-use-google-analytics-4-in-your-small-business-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/how-to-use-google-analytics-4-in-your-small-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-how-to-use-google-analytics-4-in-your-small-business-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-implementing-chatbots-into-your-small-business-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/implementing-chatbots-into-your-small-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-implementing-chatbots-into-your-small-business-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-implementing-iot-for-small-businesses-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/implementing-iot-for-small-businesses/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-implementing-iot-for-small-businesses-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-inflation-tips-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/inflation-tips/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-inflation-tips-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-influencer-marketing-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/influencer-marketing/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-influencer-marketing-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-intellectual-property-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/intellectual-property/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-intellectual-property-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-is-invoice-factoring-right-for-your-small-business-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/is-invoice-factoring-right-for-your-small-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-is-invoice-factoring-right-for-your-small-business-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-is-your-minimum-viable-product-truly-minimal-avoiding-feature-creep-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/is-your-minimum-viable-product-truly-minimal-avoiding-feature-creep/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-is-your-minimum-viable-product-truly-minimal-avoiding-feature-creep-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-keeping-website-content-fresh-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/keeping-website-content-fresh/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-keeping-website-content-fresh-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-kp-is-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/KPIs/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-kp-is-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-lead-scoring-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/lead-scoring/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-lead-scoring-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-managing-data-decay-in-small-businesses-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/managing-data-decay-in-small-businesses/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-managing-data-decay-in-small-businesses-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-marketing-goals-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/marketing-goals/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-marketing-goals-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-mental-health-in-the-workplace-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/mental-health-in-the-workplace/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-mental-health-in-the-workplace-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-nf-ts-for-sm-bs-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/NFTs-for-SMBs/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-nf-ts-for-sm-bs-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-opportunities-for-small-businesses-in-the-metaverse-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/opportunities-for-small-businesses-in-the-metaverse/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-opportunities-for-small-businesses-in-the-metaverse-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-opportunities-for-small-businesses-with-slowing-inflation-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/opportunities-for-small-businesses-with-slowing-inflation/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-opportunities-for-small-businesses-with-slowing-inflation-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-org-chart-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/org-chart/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-org-chart-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-password-manager-helping-small-businesses-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/password-manager-helping-small-businesses/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-password-manager-helping-small-businesses-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-payroll-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/payroll/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-payroll-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-pci-compliance-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/PCI-compliance/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-pci-compliance-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-personal-brand-for-entrepreneurs-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/personal-brand-for-entrepreneurs/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-personal-brand-for-entrepreneurs-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-phishing-awareness-training-for-your-small-business-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/phishing-awareness-training-for-your-small-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-phishing-awareness-training-for-your-small-business-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-pm-job-opening-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/PM-job-opening/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-pm-job-opening-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-power-of-gamification-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/power-of-gamification/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-power-of-gamification-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-productivity-blog-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/productivity-blog/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-productivity-blog-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-profit-first-method-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/profit-first-method/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-profit-first-method-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-pros-and-cons-of-using-surge-pricing-in-small-business-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/pros-and-cons-of-using-surge-pricing-in-small-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-pros-and-cons-of-using-surge-pricing-in-small-business-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-remote-pay-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/remote-pay/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-remote-pay-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-reputation-marketing-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/reputation-marketing/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-reputation-marketing-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-responsive-mobile-design-for-website-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/responsive-mobile-design-for-website/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-responsive-mobile-design-for-website-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-robust-content-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/robust-content/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-robust-content-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-rox-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/ROX/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-rox-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-sales-funnel-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/sales-funnel/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-sales-funnel-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-scoping-what-is-it-good-for-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/scoping-what-is-it-good-for/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-scoping-what-is-it-good-for-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-secure-funding-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/secure-funding/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-secure-funding-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-should-small-businesses-accept-cryptocurrency-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/should-small-businesses-accept-cryptocurrency/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-should-small-businesses-accept-cryptocurrency-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-should-small-businesses-automate-accounting-hr-customer-service-processes-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/should-small-businesses-automate-accounting-hr-customer-service-processes/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-should-small-businesses-automate-accounting-hr-customer-service-processes-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-should-small-businesses-consider-a-crypto-loan-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/should-small-businesses-consider-a-crypto-loan/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-should-small-businesses-consider-a-crypto-loan-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-should-younger-generations-buy-small-businesses-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/should-younger-generations-buy-small-businesses/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-should-younger-generations-buy-small-businesses-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-should-your-small-business-encourage-meeting-multitasking-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/should-your-small-business-encourage-meeting-multitasking/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-should-your-small-business-encourage-meeting-multitasking-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-should-your-small-business-get-verified-on-social-media-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/should-your-small-business-get-verified-on-social-media/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-should-your-small-business-get-verified-on-social-media-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-should-your-small-business-hire-neurodiverse-employees-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/should-your-small-business-hire-neurodiverse-employees/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-should-your-small-business-hire-neurodiverse-employees-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-should-your-small-business-perform-a-pen-test-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/should-your-small-business-perform-a-pen-test/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-should-your-small-business-perform-a-pen-test-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-should-your-small-business-spend-on-ld-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/should-your-small-business-spend-on-ld/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-should-your-small-business-spend-on-ld-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-should-your-small-business-use-a-subscription-business-model-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/should-your-small-business-use-a-subscription-business-model/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-should-your-small-business-use-a-subscription-business-model-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-should-your-small-business-use-a-vpn-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/should-your-small-business-use-a-VPN/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-should-your-small-business-use-a-vpn-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-smart-contracts-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/smart-contracts/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-smart-contracts-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-smb-grants-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/SMB-grants/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-smb-grants-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-smb-rewards-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/SMB-rewards/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-smb-rewards-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-social-commerce-is-booming-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/social-commerce-is-booming/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-social-commerce-is-booming-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-sow-tips-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/SOW-tips/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-sow-tips-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-spend-categories-small-businesses-should-focus-on-for-growth-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/spend-categories-small-businesses-should-focus-on-for-growth/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-spend-categories-small-businesses-should-focus-on-for-growth-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-startup-leadership-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/startup-leadership/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-startup-leadership-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-tech-co-founder-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/tech-co-founder/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-tech-co-founder-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-tech-in-the-time-of-covid-19-the-5-biggest-challenges-businesses-are-facing-and-how-to-handle-them-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/tech-in-the-time-of-covid-19-the-5-biggest-challenges-businesses-are-facing-and-how-to-handle-them/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-tech-in-the-time-of-covid-19-the-5-biggest-challenges-businesses-are-facing-and-how-to-handle-them-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-tech-is-going-to-the-dog-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/tech-is-going-to-the-dog/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-tech-is-going-to-the-dog-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-technical-debt-what-is-it-what-is-its-impact-and-what-are-strategies-to-manage-it-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/technical-debt-what-is-it-what-is-its-impact-and-what-are-strategies-to-manage-it/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-technical-debt-what-is-it-what-is-its-impact-and-what-are-strategies-to-manage-it-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-test-case-management-learn-why-its-important-and-how-you-can-perfect-it-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/test-case-management-learn-why-its-important-and-how-you-can-perfect-it/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-test-case-management-learn-why-its-important-and-how-you-can-perfect-it-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-the-5-ds-of-the-software-development-process-how-to-ensure-your-next-tech-project-is-meticulously-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/the-5-ds-of-the-software-development-process-how-to-ensure-your-next-tech-project-is-meticulously/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-the-5-ds-of-the-software-development-process-how-to-ensure-your-next-tech-project-is-meticulously-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-the-5-step-approach-to-finding-the-right-tech-partner-for-your-startup-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/the-5-step-approach-to-finding-the-right-tech-partner-for-your-startup/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-the-5-step-approach-to-finding-the-right-tech-partner-for-your-startup-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-the-gig-economy-hiring-freelancers-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/the-gig-economy-hiring-freelancers/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-the-gig-economy-hiring-freelancers-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-the-great-migration-silicon-valley-to-silicon-hills-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/the-great-migration-silicon-valley-to-silicon-hills/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-the-great-migration-silicon-valley-to-silicon-hills-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-tik-tok-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/tik-tok/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-tik-tok-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-tiktok-ban-small-business-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/tiktok-ban-small-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-tiktok-ban-small-business-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-understanding-your-customer-a-tactical-guide-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/understanding-your-customer-a-tactical-guide/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-understanding-your-customer-a-tactical-guide-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-upsell-cross-sell-blog-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/upsell-cross-sell-blog/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-upsell-cross-sell-blog-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-user-experience-ux-design-role-in-success-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/user-experience-ux-design-role-in-success/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-user-experience-ux-design-role-in-success-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-web-3-for-small-business-owners-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/web3-for-small-business-owners/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-web-3-for-small-business-owners-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-welcome-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/welcome/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-welcome-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-what-does-it-mean-to-be-agile-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/what-does-it-mean-to-be-agile/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-what-does-it-mean-to-be-agile-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-what-is-a-cybersecurity-risk-assessment-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/what-is-a-cybersecurity-risk-assessment/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-what-is-a-cybersecurity-risk-assessment-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-what-is-chatgpts-canvas-feature-and-how-your-small-business-can-use-it-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/what-is-chatgpts-canvas-feature-and-how-your-small-business-can-use-it/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-what-is-chatgpts-canvas-feature-and-how-your-small-business-can-use-it-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-what-is-google-sge-and-how-does-it-impact-seo-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/what-is-Google-SGE-and-how-does-it-impact-SEO/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-what-is-google-sge-and-how-does-it-impact-seo-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-what-is-project-crashing-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/what-is-project-crashing/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-what-is-project-crashing-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-what-is-revenue-leakage-and-how-does-it-impact-small-businesses-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/what-is-revenue-leakage-and-how-does-it-impact-small-businesses/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-what-is-revenue-leakage-and-how-does-it-impact-small-businesses-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-what-is-the-amazon-hub-delivery-program-for-small-businesses-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/what-is-the-amazon-hub-delivery-program-for-small-businesses/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-what-is-the-amazon-hub-delivery-program-for-small-businesses-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-what-small-business-owners-should-know-about-quiet-quitting-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/what-small-business-owners-should-know-about-quiet-quitting/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-what-small-business-owners-should-know-about-quiet-quitting-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-what-small-businesses-need-to-know-about-voice-search-and-seo-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/what-small-businesses-need-to-know-about-voice-search-and-SEO/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-what-small-businesses-need-to-know-about-voice-search-and-seo-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-what-the-freelance-isnt-free-law-means-for-small-businesses-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/what-the-freelance-isnt-free-law-means-for-small-businesses/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-what-the-freelance-isnt-free-law-means-for-small-businesses-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-why-small-businesses-are-working-with-gamefluencers-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/why-small-businesses-are-working-with-gamefluencers/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-why-small-businesses-are-working-with-gamefluencers-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-why-small-businesses-may-need-a-website-makeover-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/why-small-businesses-may-need-a-website-makeover/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-why-small-businesses-may-need-a-website-makeover-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-why-small-businesses-should-build-a-mobile-app-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/why-small-businesses-should-build-a-mobile-app/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-why-small-businesses-should-build-a-mobile-app-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-why-small-businesses-should-track-rage-clicks-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/why-small-businesses-should-track-rage-clicks/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-why-small-businesses-should-track-rage-clicks-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-why-use-conversational-commerce-in-your-small-business-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/why-use-conversational-commerce-in-your-small-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-why-use-conversational-commerce-in-your-small-business-index-mdx" */),
  "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-wordle-tips-index-mdx": () => import("./../../../src/templates/BlogPost.jsx?__contentFilePath=/builds/bryllyant/bryllyant-website/src/blog/blog/wordle-tips/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-jsx-content-file-path-src-blog-blog-wordle-tips-index-mdx" */)
}

